import { Component, BaseComponent, Prop, Watch, namespace } from '@zento-lib/components';
import { AddToCart as AddToCartMixin } from 'theme/stores/cart/components/AddToCart';
import { Form } from 'theme/components/Form/Form';
import { NumericInput } from 'theme/components/Form/NumericInput';
import { IFormValidation } from 'theme/components/Form/types';
import { IVSFWishlist, KEY as Wishlistkey } from 'theme/@types/vsf/stores/wishlist';
import type { IProductState } from 'theme/@types/vsf/stores/product';
import type { NotificationItem } from 'theme/stores/notification/types';
import { AppContextStore, KEY as appKey } from 'theme/@types/zento/stores/applicationContext';
import { StoreConfig } from 'theme/stores/storeConfig/storeConfig';
import { FbqConversionApiStore } from 'theme/stores/fbqConversionApi/fbqConversionApi';
import { getFBQConversionData, getFBQEventId } from '@zento-lib/components/AnalyticsServices/helpers';
import { GDPRStore } from 'theme/stores/gdpr/gdpr';
import { FreeGiftStore } from 'theme/stores/freeGift/freeGift';

import { Button, ButtonStyle } from '../../atom/Button/Button';

import { IAddToCart } from './AddToCart.d';
import style from './style.scss';

interface IAddToCartMixin {
  disabled: boolean;
  addToCart: (product: any, freeGiftProductSku: string) => Promise<any>;
}

const wishlistStore = namespace<IVSFWishlist>(Wishlistkey);
const appContextStore = namespace<AppContextStore>(appKey);

/**
 * Add to Cart
 *
 * Renders an input for changing current product quantity and a button which will add the product to cart
 */
@Component({
  mixins: [AddToCartMixin],
})
@Form()
export class AddToCart extends BaseComponent<IAddToCart, IAddToCartMixin & IFormValidation> {
  private static T = {
    addToCartQtyLabel: 'add_to_cart_qty_label',
    addToCartQtyAvailableLabel: 'add_to_cart_qty_available_label',
    addToCartBtnText: 'add_to_cart_btn_text',
    addToCartLoading: 'add_to_cart_loading',
    buyOnline: 'buy_online',
    qtyIncrementMessage: 'qty_increment_message',
  };

  /**
   * Current product
   */
  @Prop({ type: Object, required: true })
  product: IProductState;

  /**
   * Checks if the product quantity was received from the server
   */
  @Prop({ type: Boolean, default: false })
  loading?: boolean;

  /**
   * Determines if the current product quantity is rendered as information text
   */
  @Prop({ type: Boolean, default: false })
  showQuantity?: boolean;

  /**
   * Determines if the input should be rendered
   */
  @Prop({ type: Boolean, default: true })
  showInput?: boolean;

  /**
   * Determines if the input has controls (increment and decrement)
   */
  @Prop({ type: Boolean, default: true })
  controls?: boolean;

  /**
   * Determines if the quantity label is rendered as information text
   */
  @Prop({ type: Boolean, default: true })
  labelVisible?: boolean;

  /**
   * Checks if the component is part of the wishlist drawer
   */
  @Prop({ type: Boolean, default: false })
  wishlistItem?: boolean;

  /**
   * Checks if selected product swatches are available
   */
  @Prop({ type: Boolean, default: false })
  wrongAttributes?: boolean;

  /**
   * Determines button style
   */
  @Prop({ type: String, required: false, default: 'primary' })
  buttonStyle?: ButtonStyle;

  /**
   * Determines add to cart button icon
   */
  @Prop({ type: Boolean, default: false })
  addToCartMinimalist?: boolean;

  /**
   * Determines qty increments message
   */
  @Prop({ type: Boolean, default: true })
  productQtyIncrement?: boolean;

  @wishlistStore.State('items')
  items: IVSFWishlist['state']['items'];

  @appContextStore.Getter('isMobile')
  private isMobile: boolean;

  private storeConfig = new StoreConfig();
  private fbqConversionApiStore = new FbqConversionApiStore();
  private gdprStore = new GDPRStore();
  private freeGiftStore = new FreeGiftStore();

  @Watch('isAddingToCart')
  addingToCart() {
    if (this.isOnWishlist) {
      this.$store.dispatch('wishlist/removeItem', this.product);
    }
  }

  data() {
    return {
      componentLoaded: false,
      showModalScript: false,
    };
  }

  beforeMount() {
    this.onBroadcast('product-after-removevariant', this.onAfterRemovedVariant);
    this.addProductToCart = this.addProductToCart.bind(this);
  }

  mounted() {
    if (!Object.keys(this.freeGiftStore.freeGift).length) {
      this.fetchGiftProductData();
    }

    this.$nextTick(() => {
      this.$data.componentLoaded = true;
    });
  }

  beforeDestroy() {
    this.offBroadcast('product-after-removevariant');
  }

  async fetchGiftProductData() {
    return this.freeGiftStore.fetchFreeGift();
  }

  /**
   * Determines product stock information
   */
  get isProductDisabled() {
    const product = this.product;
    const stock = product.stock;
    const stockStatus = typeof stock.stock_status === 'number' ? stock.stock_status !== 1 : stock.stock_status !== '1';

    if (this.extended.$config.zento.theme.stock.msi.enabled && stock?.sources && stock?.sources.length) {
      const ss = stock.sources.reduce(
        (ret, s) => ({ ...ret, [s.source_code]: parseInt(s.quantity) }),
        {} as Record<string, number>,
      );
      const msiV =
        (ss?.furnizor < 1 && ss?.principal < 1) ||
        (ss?.furnizor < 1 && !ss?.principal) ||
        (!ss?.furnizor && ss?.principal < 1);

      return (
        !this.extended.zValidation.valid ||
        this.wrongAttributes ||
        stock.sources.every((s: Record<string, string>) => s.status === '0') ||
        msiV
      );
    }

    return (
      !this.extended.zValidation.valid ||
      this.wrongAttributes ||
      (stock &&
        stock.manage_stock &&
        stockStatus &&
        ((typeof stock.is_in_stock === 'boolean' ? !stock.is_in_stock : stock.is_in_stock !== '1') ||
          (product.qty && product.qty > stock.max_sale_qty && !this.wishlistItem))) ||
      (stock && product.type_id === 'bundle'
        ? stockStatus
        : stock.qty < 1 &&
          stock.backorders !== undefined &&
          !stock.backorders &&
          !this.extended.$config.zento.theme.productPage.backOrderDelivery)
    );
  }

  /**
   * Determines simple/configurable products
   */
  get isSimpleOrConfigurable() {
    return ['simple', 'configurable'].includes(this.product.type_id);
  }

  /**
   * Determines if product is on wishlist
   */
  get isOnWishlist(): boolean {
    return !!this.items.find((item) => item.sku === this.product.sku) || false;
  }

  onAfterRemovedVariant() {
    this.$forceUpdate();
  }

  notifyUser(notificationData: NotificationItem) {
    this.$store.dispatch('notification/spawnNotification', notificationData, { root: true });
  }

  /**
   * Add product to cart
   */
  private async addProductToCart() {
    let freeGiftProductSku = '';

    if (this.freeGiftStore.freeGift.enabled && this.freeGiftStore.freeGift.sku) {
      freeGiftProductSku = this.freeGiftStore.freeGift.sku;
    }

    await this.extended.addToCart(this.product, freeGiftProductSku).then(() => {
      if (this.extended.$config.zento.cart.toggleMicrocart) {
        this.$store.dispatch('ui/toggleMicrocart');
      }
    });

    this.broadcast('analytics/add-to-cart', {
      product: this.product,
      id: { event_id: this.eventId },
    });
    this.broadcast('analytics/add-to-cart-custom', {
      product: this.product,
      id: { event_id: this.eventId },
    });

    /**
     * TODO: should be removed in the future. Custom events added for tefal instance
     * [ZENSP-713][ZENSP-820][ZENSP-386][ZENSP-377][ZENSP-341]
     */
    if (
      this.$route?.name === 'cms-page' &&
      (this.$route?.params?.slug === 'reduceri-de-toamna' ||
        this.$route?.params?.slug === 'friteuze-cu-aer-cald-tefal-easy-fry' ||
        this.$route?.params?.slug === 'ingenio' ||
        this.$route?.params?.slug === 'multicookere-tefal' ||
        this.$route?.params?.slug === 'bunatatile-de-paste-se-prepara-cu-tefal' ||
        this.$route?.params?.slug === 'tefal-winter-sales' ||
        this.$route?.params?.slug === 'articole-de-bucatarie/oale-si-tigai/gama-de-tigai-tefal-unlimited')
    ) {
      const id = this.gdprStore.allClaims.adWords.id;
      let customId = '/tefal000';
      let secondCustomId = '/tefal0+standard';
      let cat = 'tefal0';
      let type = 'tefal000';

      // eslint-disable-line no-use-before-define
      if (
        this.$route?.params?.slug === 'bunatatile-de-paste-se-prepara-cu-tefal' ||
        this.$route?.params?.slug === 'tefal-winter-sales'
      ) {
        customId = '/tefal002';
        type = 'tefal002';
      }

      // eslint-disable-line no-use-before-define
      if (this.$route?.params?.slug === 'multicookere-tefal' || this.$route?.params?.slug === 'ingenio') {
        customId = '/tefal002';
        secondCustomId = '/tefal001+standard';
        type = 'tefal002';
        cat = 'tefal001';
      }

      // eslint-disable-line no-use-before-define
      if (this.$route?.params?.slug === 'ingenio') {
        secondCustomId = '/tefal000+standard';
        cat = 'tefal000';
      }

      // eslint-disable-line no-use-before-define
      if (
        this.$route?.params?.slug === 'reduceri-de-toamna' ||
        this.$route?.params?.slug === 'friteuze-cu-aer-cald-tefal-easy-fry'
      ) {
        customId = 'tefal002';
        type = 'tefal002';
      }

      // eslint-disable-line no-use-before-define
      if (this.$route?.params?.slug === 'articole-de-bucatarie/oale-si-tigai/gama-de-tigai-tefal-unlimited') {
        customId = '/tefal002';
        secondCustomId = '/tefal004+standard';
        cat = 'tefal004';
        type = 'tefal002';
      }

      this.broadcast('analytics/custom-add-to-cart', {
        id: id,
        customId: customId,
        secondCustomId: secondCustomId,
      });

      const hasScript = document.querySelector(`noscript[id="${id}${customId}"]`);

      if (!hasScript) {
        const ns = document.createElement('noscript');
        ns.id = id + customId;
        document.body.appendChild(ns);

        const oImg = document.createElement('img');
        oImg.src =
          // eslint-disable-next-line no-template-curly-in-string
          `https://ad.doubleclick.net/ddm/activity/src=9295049;type=${type};cat=${cat};dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;ord=1?`;
        oImg.alt = '';
        oImg.width = 1;
        oImg.height = 1;

        document.getElementById(id + customId).appendChild(oImg);
      }
    }

    /**
     *  TODO: should be removed in the future. Custom events added for rowenta instance
     * [ZENSP-722][ZENSP-762][ZENSP-346]
     */
    if (
      (this.$route?.name === 'cms-page' || this.$route?.params?.parentSku) &&
      (this.$route?.params?.slug === 'aniversare_1_an' ||
        this.$route?.params?.slug === 'rowenta-karl-lagerfeld' ||
        this.$route?.params?.slug === 'x-force-flex' ||
        this.$route?.params?.slug === 'rowenta-easter-promo' ||
        this.$route?.params?.slug === 'rowenta-winter-sales' ||
        this.$route?.path ===
          '/perie-reparatoare-cu-abur-rowenta-hair-therapisttm-cf9940f0-165-w-tehnologie-thermal-care-peri-naturali-invelis-keratin-oprire-automata-afisaj-led-geanta-pentru-transport-negru-maro')
    ) {
      const id = this.gdprStore.allClaims.adWords.id;
      let customId = '/rowen002';
      let secondCustomId = '/rowen0+standard';
      let cat = 'rowen0';
      let type = 'rowen002';

      // eslint-disable-line no-use-before-define
      if (this.$route?.params?.slug === 'ingrijirea-locuintei/aspiratoare-verticale') {
        customId = '/rowen0';
        secondCustomId = '/rowen00+standard';
        cat = 'rowen00';
        type = 'rowen002';
      }

      // eslint-disable-line no-use-before-define
      if (this.$route?.params?.slug === 'rowenta-karl-lagerfeld') {
        secondCustomId = '/rowen000+standard';
        cat = 'rowen000';
        type = 'rowen002';
      }

      // eslint-disable-line no-use-before-define
      if (
        this.$route?.params?.slug ===
        '/perie-reparatoare-cu-abur-rowenta-hair-therapisttm-cf9940f0-165-w-tehnologie-thermal-care-peri-naturali-invelis-keratin-oprire-automata-afisaj-led-geanta-pentru-transport-negru-maro'
      ) {
        customId = '/rowen0';
        secondCustomId = '/rowen008+standard';
        cat = 'rowen008';
        type = 'rowen0';
      }

      this.broadcast('analytics/custom-add-to-cart', {
        id: id,
        customId: customId,
        secondCustomId: secondCustomId,
      });

      const hasScript = document.querySelector(`noscript[id="${id}${customId}"]`);

      if (!hasScript) {
        const ns = document.createElement('noscript');
        ns.id = id + customId;
        document.body.appendChild(ns);

        const oImg = document.createElement('img');
        oImg.src =
          // eslint-disable-next-line no-template-curly-in-string
          `https://ad.doubleclick.net/ddm/activity/src=9259359;type=${type};cat=${cat};dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;ord=1?`;
        oImg.alt = '';
        oImg.width = 1;
        oImg.height = 1;

        document.getElementById(id + customId).appendChild(oImg);
      }
    }

    if (this.storeConfig.storeConfigurations.facebookConversionGeneralActive) {
      this.fbqConversionApiStore.send(
        getFBQConversionData({
          items: this.product,
          eventName: 'AddToCart',
          eventId: this.eventId,
          sourceUrl: this.product.url_path.startsWith('/') ? this.product.url_path : '/' + this.product.url_path,
          content_name: this.product.name,
          content_category:
            this.extended.$config.zento.analytics.customConfig &&
            this.$store.getters['product/breadcrumbs']?.routes?.length
              ? this.$store.getters['product/breadcrumbs']?.routes
                  ?.map((cat: Record<string, any>) => cat.name)
                  .join(', ')
              : this.product.category
              ? this.product.category.map((cat) => cat.name).join(', ')
              : '',
        }),
      );
    }
  }

  private get eventId() {
    return getFBQEventId('AddToCart', [this.product.id]);
  }

  render() {
    if ('hideAddToCart' in this.extended.$config.zento && this.extended.$config.zento.hideAddToCart) {
      return null;
    }

    const product = this.product;
    const stock = product.stock;
    const minQty = stock ? (parseFloat(stock.min_sale_qty) > 0 ? parseFloat(stock.min_sale_qty) : 1) : 1;
    const maxQty = stock ? (parseFloat(stock.max_sale_qty) > 0 ? parseFloat(stock.max_sale_qty) : Infinity) : Infinity;

    return (
      <div class={style.addToCart}>
        {stock && stock.qty_increments && parseFloat(stock.qty_increments) > 1 && this.productQtyIncrement ? (
          <p class={style.qtyIncrenment}>
            {this.getTranslation({
              id: AddToCart.T.qtyIncrementMessage,
              data: { qty_increnment: stock.qty_increments },
            })}
          </p>
        ) : null}
        {this.gdprStore?.commerceConnector?.active && this.isProductDisabled && product.type_id !== 'bundle' ? (
          <div>
            <Button
              class={style.buyOnline}
              styleType={this.buttonStyle}
              data-tooltip={this.getTranslation({ id: AddToCart.T.addToCartBtnText })}
              dataTestId='commerceConnector'>
              <span
                class='button button-buy btn btn-action cc-fi-button'
                data-ean={product.cod_ean === undefined ? '' : product.cod_ean}
                data-analytics-buyonlinebutton={this.getTranslation({ id: AddToCart.T.buyOnline })}
                data-analytics-buyonlinebutton-productname={product.name}
                data-analytics-buyonlinebutton-productid={product.id}
                data-analytics-buyonlinebutton-productprice={product.price}>
                {this.getTranslation({ id: AddToCart.T.buyOnline })}
              </span>
            </Button>
          </div>
        ) : (
          <div>
            <span class={style.inputQty}>
              {product.type_id !== 'grouped' && this.showInput ? (
                <NumericInput
                  state={product}
                  valueKeeper='qty'
                  name={'addToCart-' + product.id}
                  validateOn='input'
                  required={true}
                  min={minQty}
                  max={maxQty}
                  step={
                    stock && stock.qty_increments && parseFloat(stock.qty_increments) >= 1
                      ? parseFloat(stock.qty_increments)
                      : 1
                  }
                  controls={this.controls}
                  class={style.addToCartInput}
                  key={'add-to-cart-controls-' + product.id}>
                  {this.labelVisible ? (
                    this.isSimpleOrConfigurable && this.showQuantity ? (
                      <span slot='before' class={style.addToCartLabel}>
                        {this.getTranslation({
                          id: AddToCart.T.addToCartQtyAvailableLabel,
                          data: { qty_available: !this.loading ? maxQty : null },
                        })}
                      </span>
                    ) : (
                      <span slot='before' class={style.addToCartLabel}>
                        {this.getTranslation({ id: AddToCart.T.addToCartQtyLabel })}
                      </span>
                    )
                  ) : null}
                </NumericInput>
              ) : null}

              {this.showInput && 'salesuom' in this.product ? (
                <div class={style.measure}>{this.product.salesuom}</div>
              ) : null}
            </span>

            <Button
              class={{
                [style.addToCartMinimalist]: this.isMobile && this.addToCartMinimalist,
              }}
              styleType={this.buttonStyle}
              handler={this.addProductToCart}
              loader={this.$data.isAddingToCart}
              disabled={this.isProductDisabled || this.$data.isAddingToCart}
              data-tooltip={this.getTranslation({ id: AddToCart.T.addToCartBtnText })}
              dataTestId='addToCart'>
              {this.getTranslation({ id: AddToCart.T.addToCartBtnText })}
            </Button>
          </div>
        )}
      </div>
    );
  }
}
