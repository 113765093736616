import { Format } from '@zento-lib/components/i18n/inline';

import type { NotificationItem } from '../../notification/types';
import Product from '../../catalog/types/Product';

export const AddToCart = {
  name: 'AddToCart',

  data() {
    return {
      isAddingToCart: false,
    };
  },

  props: {
    product: {
      required: true,
      type: Object,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    async addToCart(product: Product, freeGiftProductSku: string) {
      this.isAddingToCart = true;

      try {
        const diffLog = await this.$store.dispatch('cart/addItem', { productToAdd: product });

        if (diffLog.clientNotifications && diffLog.clientNotifications.length > 0) {
          if (freeGiftProductSku) {
            await this.$store.dispatch('cart/syncFreeGift');
          }

          diffLog.clientNotifications.forEach((notificationData: NotificationItem) => {
            this.notifyUser(notificationData);
          });
        } else {
          this.notifyUser({
            type: 'success',
            message: Format.message('product_added_to_cart'),
            action1: { label: Format.message('notification_action') },
            action2: {
              label: Format.message('cart_go_to_checkout'),
              action: () => this.$router.push(this.extended.localizedRoute('/checkout', '')),
            },
          });
        }

        return diffLog;
      } catch (err) {
        this.notifyUser({
          type: 'error',
          message: err,
          action1: { label: Format.message('notification_action') },
        });

        return null;
      } finally {
        this.isAddingToCart = false;
      }
    },
  },
};
